export default [
  {
    name: 'Сверху справа',
    id: 'top-right',
  },
  {
    name: 'Сверху слева',
    id: 'top-left',
  },
  {
    name: 'Сверху',
    id: 'top',
  },
  {
    name: 'Слева',
    id: 'left',
  },
  {
    name: 'Справа',
    id: 'right',
  },
  {
    name: 'По центру',
    id: 'center',
  },
  {
    name: 'Снизу слева',
    id: 'bottom-left',
  },
  {
    name: 'Снизу справа',
    id: 'bottom-right',
  },
  {
    name: 'Снизу справа',
    id: ' bottom-right,',
  },
  {
    name: 'Снизу',
    id: ' bottom,',
  },
]
