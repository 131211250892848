<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Настройка: Настройки изображений</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Редактирование настроек</h5>
        </div>
      </div>
      <div class="card-body">
        <a-form-model ref="form" :model="settings">
          <a-form-model-item class="text_area" label="Изображение водного знака" required>
            <a-upload
              :disabled="$route.query.is_preview"
              list-type="picture-card"
              :file-list="fileList"
              @change="handleChange"
            >
              <div v-if="fileList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  Загрузить
                </div>
              </div>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item label="Ширина водного знака(px)" required>
            <a-input v-model="settings.width_watermark" />
          </a-form-model-item>
          <a-form-model-item label="Высота водного знака(px)" required>
            <a-input v-model="settings.height_watermark" />
          </a-form-model-item>
          <a-form-model-item label="Прозрачность водного знака (0 - 100%)" required>
            <a-input v-model="settings.opacity_watermark" />
          </a-form-model-item>
          <a-form-model-item class="text_area" label="Расположение водного знака" required>
            <a-select v-model="settings.position_watermark">
              <a-select-option v-for="type in positions" :key="type.id" :value="type.id">
                {{ type.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
        <a-button class="ml-auto d-block" type="primary" @click="submit" :disabled="isDisabled">
          Сохранить изменения
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import positions from '@/entities/positions'

export default {
  name: 'ImageSettings',

  data() {
    return {
      settings: {
        width_watermark: '',
        height_watermark: '',
        opacity_watermark: '',
        position_watermark: '',
      },
      fileList: [],
      positions,
    }
  },

  created() {
    this.getSettings()
  },

  computed: {
    isDisabled() {
      let isDisabled = false

      for (const set in this.settings) {
        if (!`${this.settings[set]}`) {
          isDisabled = true
        }
      }

      if (!this.fileList.length) isDisabled = true

      return isDisabled
    },
  },

  methods: {
    async getSettings() {
      try {
        this.settings = (await this.$services.get('admin/image_settings')).data.data.image_setting
        this.fileList.push({
          url: this.settings.icon,
          uid: '-4',
          name: 'image.png',
          status: 'done',
        })
      } catch (e) {
        console.error(e)
      }
    },

    handleChange({ fileList }) {
      this.fileList = fileList
    },

    async submit() {
      if (this.fileList[0] && this.fileList[0].originFileObj) {
        this.settings.watermark = this.fileList[0].originFileObj
      }

      const formData = new FormData()
      for (const [key, value] of Object.entries(this.settings)) {
        formData.append(key, value)
      }

      try {
        await this.$services.post('admin/image_settings', formData)
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ant-form {
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
}
</style>
